<template>
	


	<section style="padding-top: 50px;">
		

		<div class="container ">

		<router-link to="/" class="btn p-0 color-2"><b> <i class="bi bi-chevron-left"></i> Back</b> </router-link>

		<h1 style="color: #f1f1f1;">Table Reservation</h1>


		<div class="row shadow">

			<div class="col-md-4" style="background-image: url('/assets/images/gallery-03.jpeg'); background-size: cover; background-position: center; min-height: 300px;"></div>
			<div class="col-md-8" style="background-color: #1a1a1a; padding-top: 10px; padding-bottom: 100px;">

				<div style="padding-bottom: 0px;">

					<h6 class="color-1">Table reservation for : {{this.$store.state.picked_date}} </h6>
					

					<h6 class="color-1">Pick a table below:(All blured booths are booked)</h6>
					
				</div>



				<div>


				<div style=" overflow: auto; white-space: nowrap;">

					<div>
					<span class="" v-for="m in map"> 

					<label v-for="t in tables" @click="pick_table(t.id)" :class="{ 'opacity' : t.booked, 'opacity2' : t.id==this.$store.state.picked_table } "> 
	
						<!--<span style="position:absolute; margin-top:20px;" v-if="t.tableName==m && t.booked==true "> booked </span>-->

						<img :src=" 'assets/plan/A2.png' " class="seat" v-if="m=='BOOTH A2' && m==t.tableName ">
						<img :src=" 'assets/plan/A3.png' " class="seat" v-if="m=='BOOTH A3' && m==t.tableName ">

						<img :src=" 'assets/plan/A4.png' " class="seat" v-if="m=='BOOTH A4' && m==t.tableName ">

						<img :src=" 'assets/plan/B4.png' " class="seat" v-if="m=='BOOTH B4' && m==t.tableName ">

						<img :src=" 'assets/plan/B3.png' " class="seat" v-if="m=='BOOTH B3' && m==t.tableName  "> 

							<img :src=" 'assets/plan/B2.png' " class="seat" v-if="m=='BOOTH B2' && m==t.tableName ">

							<img :src=" 'assets/plan/A1.png' " class="seat" v-if="m=='BOOTH A1' && m==t.tableName  ">

							<img :src=" 'assets/plan/A5.png' " class="seat" v-if="m=='BOOTH A5' && m==t.tableName ">

							<img :src=" 'assets/plan/A6.png' " class="seat" v-if="m=='BOOTH A6' && m==t.tableName ">

							<img :src=" 'assets/plan/A7.png' " class="seat" v-if="m=='BOOTH A7' && m==t.tableName ">

							<img :src=" 'assets/plan/A8.png' " class="seat" v-if="m=='BOOTH A8' && m==t.tableName ">

							<img :src=" 'assets/plan/B5.png' " class="seat" v-if="m=='BOOTH B5'  && m==t.tableName ">

							<img :src=" 'assets/plan/B6.png' " class="seat" v-if="m=='BOOTH B6' && m==t.tableName ">


					<img :src=" 'assets/plan/B7.png' " class="seat" v-if="m=='BOOTH B7' && m==t.tableName ">

					<img :src=" 'assets/plan/B8.png' " class="seat" v-if="m=='BOOTH B8' && m==t.tableName ">


					<img :src=" 'assets/plan/B1.png' " class="seat" v-if="m=='BOOTH B1' && m==t.tableName ">

					

					<img :src=" 'assets/plan/D1.png' " class="seat2" v-if="m=='BOOTH D1' && m==t.tableName ">

					<img :src=" 'assets/plan/D2.png' " class="seat2" v-if="m=='BOOTH D2' && m==t.tableName ">

					<img :src=" 'assets/plan/C1.png' " class="seat2" v-if="m=='BOOTH C1' && m==t.tableName ">

					<img :src=" 'assets/plan/C2.png' " class="seat2" v-if="m=='BOOTH C2' && m==t.tableName ">

					<img :src=" 'assets/plan/C3.png' " class="seat2" v-if="m=='BOOTH C3' && m==t.tableName ">

					<img :src=" 'assets/plan/C4.png' " class="seat2" v-if="m=='BOOTH C4' && m==t.tableName ">

					<img :src=" 'assets/plan/D3.png' " class="seat2" v-if="m=='BOOTH D3'  && m==t.tableName " style="margin-left: 65px; margin-top: 0;">

						
					 </label>

					 <!-- dj booth -->
					<img :src=" 'assets/plan/Stage & Dj Booth.png' " class="seat" v-if="m=='DJ' " style="margin-top: 50px;">
					 <!-- dj booth -->

					 <img :src=" 'assets/plan/Bar.png' " class="seat" v-if="m=='BAR' ">

					<div v-if="m=='BOOTH B2'">  </div>


					<span v-if="m==''" style="margin-right: 5px;"></span>

					<div v-if="m=='BOOTH B1'">  </div>

					<span v-if="m==''" style="margin-right: 70px;"></span>

					<div v-if="m=='BOOTH C4'">  </div>

					
					</span>
					</div>
					
				</div>


					
					<div class="row">

						<div class="col-md-2 col-2" v-for="t in tables">

	
							<!--
							<button :class="{ 'text-danger' : t.booked==true }" @click="pick_table(t.id)" :disabled="t.booked" class="btn menu-btn">  <b> <i class="bi bi-check-circle" v-if="t.id==this.$store.state.picked_table"></i>  {{t.tableName}} <small class="text-danger" v-if="t.booked">(Booked)</small> </b> </button>-->
								
						</div>
						
					</div>


					<div style="padding-top:20px;">


						<button @click="checkout()" class="btn btn-1">Check Out</button>
					</div>



				</div>

				
				

			</div>
			
		</div>
					
		</div>


	</section>



	<Footer />


</template>


<script>
	import Footer from './Footer'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	export default{
		name : 'home',
		components : {Footer},
		data(){
			return{
				map : ['BOOTH A2','BOOTH A3','BOOTH A4','DJ','BOOTH B4','BOOTH B3','BOOTH B2','BOOTH A1', 'BOOTH A5','BOOTH A6','BOOTH A7','BOOTH A8','','BOOTH B5', 'BOOTH B6', 'BOOTH B7', 'BOOTH B8', 'BOOTH B1','BAR', 'BOOTH D1', 'BOOTH D2','', 'BOOTH C1', 'BOOTH C2', 'BOOTH C3', 'BOOTH C4', 'BOOTH D3' ],
				tables : [],
				display_date : '',
				months : ["January","February","March","April","May","June","July","August","September","October","November","December"],
				days : ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
				items : [],
				picked_table : ''
			}
		},

		methods : {

			pick_table(id){

				let booked = false

				
				this.items.forEach((data)=>{
					if(id == data.slotId && data.bookDate==this.$store.state.picked_date ){
						booked = true
					}
				})

				if(booked==true){
					Swal.fire({
				  title: "Please note",
				  text: "This booth is already taken",
				  icon: "info"
				})
					return
				}else{
					this.pick_table_now(id)
				}
			
			},

			pick_table_now(id){
				this.$store.state.picked_table = id
				localStorage.setItem('picked_table',id)
				//this.picked_table = id 
			
			},

			async get_tables(){
				const res  = await axios.get(this.$store.state.url+'api/get-tables').then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				//this.tables = res 
				
				res.forEach((data)=>{
					let item = ''
					let booked = false

					this.items.forEach((data2)=>{
						if(data2.slotId==data.id && data2.bookDate==this.$store.state.picked_date ){
							booked = true
						}
					})

					
					item = { 'tableName' : data.tableName, 'booked' : booked, 'id' : data.id }
					this.tables = this.tables.concat(item)
					

				})


			},


			async check_tables(){
				const res  = await axios.get(this.$store.state.url+'api/check-tables/'+this.$store.state.picked_date).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})

				this.items = res
				this.get_tables() 
			
			},


			checkout(){
				if(!this.$store.state.picked_table){
					Swal.fire({
				  title: "Error",
				  text: "Please pick a table",
				  icon: "error"
				})
					return
				}
				this.$router.push('/checkout')
			}
		},

		created(){
			this.check_tables()
		

			var year = new Date(this.$store.state.picked_date).getFullYear()
			var month = new Date(this.$store.state.picked_date).getMonth()
			month = this.months[month]
			var day = new Date(this.$store.state.picked_date).getDay()

			day  = this.days[day]
			this.display_date = day+', '+month+' '+year


		}
	}

</script>



